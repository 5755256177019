import { Component, OnInit, Input, NgZone } from '@angular/core';
import { TokenControllerService } from '../token-controller.service';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lio-header-buttons',
  templateUrl: './lio-header-buttons.component.html',
  styleUrls: ['./lio-header-buttons.component.scss'],
})
export class LioHeaderButtonsComponent implements OnInit {

  @Input() page: string;

  constructor(
    private tokenController: TokenControllerService,
    private router: Router,
    public toastController: ToastController,
    private translationService: TranslateService,
    private zone: NgZone
    ) { }

  ngOnInit() {}

  showSettings() {
    switch (this.page) {
      case 'admins-list':
        this.presentToast('admins');
        break;
      case 'gateways-list':
        this.presentToast('gateways');
        break;
      case 'add-gateway':
        this.presentToast('add-gateway');
        break;
      case 'gateway-detail':
        this.presentToast('gateway-detail');
        break;
      default:
        this.presentToast('some');
        break;
    }
  }

  showHelp() {
    switch (this.page) {
      case 'admins-list':
        this.presentToast('admins');
        break;
      case 'gateways-list':
        this.presentToast('gateways');
        break;
      case 'add-gateway':
        this.presentToast('add-gateway');
        break;
      case 'gateway-detail':
        this.presentToast('gateway-detail');
        break;
      default:
        this.presentToast('some');
        break;
    }
  }

  logout() {
    // TODO: Add confirmation modal.
    this.tokenController.forgetMe();
    this.router.navigateByUrl('/login');
  }

  async presentToast(txt: string) {
    const toast = await this.toastController.create({
      message: 'Showing ' + txt + ' settings.',
      duration: 2000
    });
    toast.present();
  }

}
