import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lio-list-filter',
  templateUrl: './lio-list-filter.component.html',
  styleUrls: ['./lio-list-filter.component.scss'],
})
export class LioListFilterComponent implements OnInit {

  private filters: any;

  @Output() newFilters: EventEmitter<null> = new EventEmitter();

  constructor() {
    this.filters = {
      name: '',
      registered: '',
      type: '',
      parameter1: ''
    };
  }

  ngOnInit() {}

  shootNewFilters() {
    console.log('Shooting new filters');
    this.newFilters.emit(this.filters);
  }
}
