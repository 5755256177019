import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Socket } from 'ngx-socket-io';
import { ToastController } from '@ionic/angular';

export enum ActuatorStatus {
  OFF = 0,
  ON = 1,
  UNKNOWN = 1337
}
@Component({
  selector: 'lio-actuator',
  templateUrl: './lio-actuator.component.html',
  styleUrls: ['./lio-actuator.component.scss'],
})

export class LioActuatorComponent implements OnInit {

  @Input() feature: any;

  private actuatorStatusEnum = ActuatorStatus;
  private actuatorStatus: ActuatorStatus;

  private message: {
    data: {
      action: number
    },
    gatewayUuid: string,
    deviceUuid: string,
    featureId: number,
    type: string
  };

  // Mockup
  private level = 50;

  constructor(
    private translationService: TranslateService,
    public toastController: ToastController,
    private socket: Socket
  ) { }

  ngOnInit() {
    this.actuatorStatus = ActuatorStatus.UNKNOWN;
    this.message = {
      data: {
        action: 0
      },
      deviceUuid: this.feature.deviceUuid,
      gatewayUuid: this.feature.gatewayUuid,
      //featureId: this.feature.id,
      featureId: this.feature.index,
      type: 'feature'
    };

    // Listening ACK

    this.socket.on(this.feature.gatewayUuid + '/' + this.feature.deviceUuid + '/' + this.feature.index + '/cmdAck', (data: any) => {
      console.log(data);
      switch (data.status) {
        case 0: // SUCCESS
          // Trigger Toast
          this.showAckToast(data.data.reason);
          // Update status for UI
          this.actuatorStatus = this.message.data.action;
          break;
        case 2: // INVALID CMD
          // Trigger Toast
          this.showAckToast(data.data.reason);
          break;
        case 8: // TIMEOUT
          // Trigger Toast
          this.showAckToast(data.data.reason);
          break;
        case 20: // DEVICE NOT FOUND
          // Trigger Toast
          this.showAckToast(data.data.reason);
          break;
        default:
          console.log('Status not handled');
      }
    });
  }

  sendOn() {
    this.message.data.action = 1;
    this.socket.emit('cmd', this.message);
    console.log(this.message);
    console.log('ON sent');
  }

  sendOff() {
    this.message.data.action = 0;
    this.socket.emit('cmd', this.message);
    console.log(this.message);
    console.log('OFF sent');
  }

  sendToggle() {
    console.log('FEATURE');
    console.log(this.feature);
    console.log('TOGGLE');
  }

  async showAckToast(txt: string) {
    const toast = await this.toastController.create({
      message: this.translationService.instant('ACTUATOR.ACK_TOAST_MESSAGE', { message: txt }),
      duration: 2000
    });
    toast.present();
  }

}
