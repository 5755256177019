import { Component, OnInit, Input } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { GatewayControllerService } from '../gateways/services/gateway-controller.service';
import { TokenControllerService } from '../token-controller.service';

@Component({
  selector: 'lio-log-viewer',
  templateUrl: './lio-log-viewer.component.html',
  styleUrls: ['./lio-log-viewer.component.scss'],
})
export class LioLogViewerComponent implements OnInit {

  private logs = '';

  @Input() data: any;

  constructor(
    private socket: Socket,
    private gatewayControllerService: GatewayControllerService,
    private tokenControllerService: TokenControllerService
    ) { }

  ngOnInit() {
    // Fetching last registered logs

    this.gatewayControllerService.getPluginLastLogs(this.data.gatewayUuid, this.data.uid).subscribe(
      lastLogs => {
        lastLogs.forEach(data => {
          this.pushLog(JSON.stringify(data));
        });
      }
    );
    this.socket.on(this.data.gatewayUuid + '/' + this.data.uid + '/eventStatus',
    (message) => {
      this.pushLog(JSON.stringify(message.data));
    });

    this.pushLog(JSON.stringify(this.data));
    this.pushLog('Logs for:');
  }

  pushLog(log: string) {
    this.logs = log + String.fromCharCode(13, 10) + this.logs; // See last first.
  }

  onClick(ev: Event) {
    ev.stopPropagation();
  }
}
