import { Component, OnInit, Input } from '@angular/core';
import { LioListOptions } from '../lio-list/lio-list.component';
import { TranslateService } from '@ngx-translate/core';

export interface LioDetailOptions {
  cells: {attribute: string, label: string}[][];
  data: any;
}

export enum RealmRegistrationState {
  UNKNOWN = 0,
  DISCOVERED = 1,
  CONNECTED = 2,
  REGISTERED = 3
}

@Component({
  selector: 'lio-detail',
  templateUrl: './lio-detail.component.html',
  styleUrls: ['./lio-detail.component.scss'],
})
export class LioDetailComponent implements OnInit {

  @Input() options: LioDetailOptions = {
    cells: [[]],
    data: {}
  };

  constructor(
    private translateService: TranslateService
    ) { }

  ngOnInit() {}

  getRealmRegistrationStateLabel(state: number) {
    return this.translateService.instant('REALM_REGISTRATION_STATE.' + RealmRegistrationState[state]);
  }

  GetPropertyValue(obj: any, dataToRetrieve: any) {
    return dataToRetrieve
      .split('.') // split string based on `.`
      .reduce((o: any, k: any) => {
        return o && o[k]; // get inner property if `o` is defined else get `o` and return
      }, obj); // set initial value as object
  }
}
