import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TokenControllerService } from './token-controller.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  baseUrl: string;

  constructor(
    private router: Router,
    private tokenController: TokenControllerService) {}

  async canActivate() {
    let activate = false;
    await this.tokenController.isAuthenticated().then(
      res => activate = res
    );
    // Check to see if a user has a valid token
    if (activate) {
        // If they do, return true and allow the user to load app
        return true;
    }

    // If not, they redirect them to the login page
    this.router.navigate(['/login']);
    return false;
  }
}
